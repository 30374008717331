@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
  body {
    @apply font-[Raleway];
  }

  li {
    @apply px-4;
    @apply cursor-pointer
  }
}

.loader {
  text-align: center;
  background-color: #071427;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 280px;
}

.content-div:hover {
  background-image: linear-gradient(to right,
      rgba(112, 157, 255, 0.7),
      hsla(242, 74%, 61%, 0.7)) !important;
  transform: scale(1.02);
}

.social-links .social-link .social-name {
  color: rgb(154, 153, 153);
  transition: .5s;
}

.social-links .social-link .social-name::before {
  transition: .5s;
}

.social-links .social-link .social-name:hover::before {
  transform: scale(1.6);
  box-shadow: 15px #ffffff;
}

.social-links .social-link .social-name:hover {
  color: #ffffff;
  box-shadow: 5px #ffffff;
  text-shadow: 0 0 15px #ffffff;
}

.fade-text {
  opacity: 0%;
}

.social-link:hover .fade-text {
  animation: fadeIn .3s;
  -webkit-animation: fadeIn .6s;
  -moz-animation: fadeIn .6s;
  -o-animation: fadeIn .6s;
  -ms-animation: fadeIn .6s;
  animation-fill-mode: forwards;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}